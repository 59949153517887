.comments {
    background-color: #101010;
    padding: 25px 0;
    overflow: hidden;
}

.comments .top {
    text-align: center;
}

.comments .top h1 {
    font-size: 39px;
    font-weight: 500;
    background: linear-gradient(90deg, #9F9F9F 0%, #FFFFFF 51.5%, #999999 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
}

.comments .bottom {
    margin-top: 25px;
    width: max-content;
}

.comments .bottom .comments-wrapper {
    display: flex;
    gap: 50px;
    position: relative;
}

.comments .bottom .comments-wrapper .comment {
    width: 850px;
    padding: 50px 100px;
    border-radius: 20px;
    background: #191919;
    background-image: url(../../imgs/qoshtirnoq.png);
    background-repeat: no-repeat;
    background-position: 40px 50px;
    animation: 20s slide infinite linear;
}

@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.comments .bottom .comments-wrapper .comment .content h2 {
    text-align: center;
    font-weight: 500;
    font-size: 25px;
    line-height: 27.5px;
    background: linear-gradient(90deg, #9F9F9F 0%, #FFFFFF 51.5%, #999999 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
}

.comments .bottom .comments-wrapper .comment .user {
    display: flex;
    margin-top: 50px;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.comments .bottom .comments-wrapper .comment .user img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.comments .bottom .comments-wrapper .comment .user p {
    font-weight: 400;
    font-size: 16px;
    line-height: 17.6px;
    color: #FFFFFF;
}

.comments .bottom .comments-wrapper .comment .user span {
    font-weight: 250;
    font-size: 14px;
    line-height: 15.4px;
    color: #6B6B6B;
}