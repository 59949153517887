
.text{
    height: 100%;
    .text-bottom{
        height: 100%;
        form{
            height: 100%;
        }
        textarea{
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            background-color: rgba(224, 224, 224, 1);
            border-radius: 15px;
            padding: 15px;
            font-weight: 300;
            font-size: 20px;
            line-height: 20px;
            resize: none;
            &::-webkit-scrollbar-thumb{
                display: none;
            }
        }
    }
}