@media screen and (max-width: 1024px) and (min-width: 769px) {
  :root {
    --main-sections-padding: 15px 50px;
    --p-size: 1rem;
    --top-p-size: 20px;
  }
  nav .left img {
    width: 150px;
  }
  nav .center ul {
    gap: 15px;
  }
  nav .right {
    gap: 15px;
  }
  nav .right select {
    padding: 5px;
  }
  nav .right .signup, nav .right .bot {
    padding: 5px 10px;
  }
  section header {
    margin-top: 75px;
  }
  section header .left-head h1 {
    font-size: 2.5rem;
  }
  section header .right-head .profile-container .profile-card {
    height: 200px;
  }
  section .componies .logos {
    padding: 20px 0;
  }
  section .componies .logos .logos-slide img {
    max-width: 125px;
    margin: 0 20px;
  }
  section .services-main .bottom {
    height: 450px;
  }
  section .services-main .bottom .left .text h1 {
    font-size: 1.5rem;
  }
  section .services-main .bottom .left .text p {
    font-size: 0.9rem;
  }
  section .services-main .bottom .right .service__item .text h1 {
    font-size: 1.5rem;
  }
  section .services-main .bottom .right .service__item .text p {
    font-size: 0.9rem;
  }
  section .services-main .bottom .right .service__items div h1 {
    font-size: 1.5rem;
  }
  section .advantages .bottom .card {
    gap: 50px;
  }
  section .advantages .bottom .card .left h1 {
    font-size: 40px;
  }
  section .advantages .bottom .card .right img {
    width: 350px;
  }
  section .guide .left h1 {
    font-size: 60px;
  }
  section .guide .right iframe {
    height: 300px;
  }
  footer .top .left img {
    width: 150px;
  }
  footer .top .right ul li img {
    width: 25px;
  }
}
@media screen and (max-width: 1341px) {
  :root {
    --main-sections-padding: 15px 50px;
  }
  header {
    padding: var(--main-sections-padding);
  }
}
@media screen and (max-width: 1024px) and (min-width: 425px) {
  .about .left h1 {
    font-size: 40px !important;
    line-height: 40px !important;
  }
  .about .left p {
    line-height: 20px !important;
  }
}
@media screen and (max-width: 901px) {
  .services-main .bottom {
    height: 1000px !important;
    flex-direction: column;
  }
  .services-main .bottom .left,
  .services-main .bottom .right {
    width: 100%;
    height: 50% !important;
  }
  .services-main .bottom .left {
    height: 500px !important;
  }
}/*# sourceMappingURL=1024.css.map */