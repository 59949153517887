.sts {
  width: 100%;
  height: 100vh;
  background-repeat: repeat;
  background-size: cover;
  padding: 100px;
  padding-top: 120px;
  justify-content: center;
  background-image: url(../imgs/servicebg.png);

  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 2420px;
    margin: 0 auto;
  }

  .top {
    h1 {
      font-weight: 600;
      font-size: 60px;
      line-height: 60px;
    }
  }

  .center {
    display: flex;
    gap: 10px;

    .mark {
      background: var(--pink-gradient);
      color: var(--white);
    }

    a {
      padding: 10px 30px;
      background-color: var(--white);
      border-radius: 10px;
      font-weight: 600;
      font-size: var(--p-size);
      transition: 0.5s;

      &:hover {
        background: var(--pink-gradient);
        color: var(--white);
      }
    }
  }

  .tools {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .tool {
      width: 33%;
      height: 350px;
      background-color: var(--white);
      border-radius: 20px;
      padding: 25px;

      .upload_top,
      .language-top,
      .result-top,
      .text-top,
      .voice-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 25px;
          line-height: 25px;
          font-weight: 500;
        }

        button {
          padding: 10px 25px;
          border-radius: 10px;
          border: none;
          outline: none;
          cursor: pointer;
          background: var(--pink-gradient);
          font-size: 20px;
          line-height: 20px;
          color: var(--white);
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        .spinner {
          border: 2px solid rgba(0, 0, 0, 0.1);
          border-left-color: white;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          animation: spin 1s linear infinite;
        }
      }

      .upload,
      .language,
      .result,
      .text,
      .voice {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 100%;
      }

      .upload-bottom {
        width: 100%;
        height: 100%;

        .file-upload-form {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .file-upload-label {
          input {
            display: none;
          }

          svg {
            height: 50px;
            fill: var(--pink-color);
            margin-bottom: 20px;
          }

          width: 100%;
          height: 100%;
          cursor: pointer;
          background-color: rgba(224, 224, 224, 1);
          padding: 30px 70px;
          border-radius: 15px;
          border: 2px dashed rgba(0, 0, 0, 0.5);
        }

        .file-upload-design {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }

        .browse-button {
          background-color: var(--pink-color);
          padding: 5px 15px;
          border-radius: 10px;
          color: var(--white);
          transition: all 0.3s;
        }
      }

      .language-bottom,
      .voice-bottom {
        display: flex;
        flex-direction: column;
        gap: 20px;

        select {
          width: 100%;
          background-color: rgba(224, 224, 224, 1);
          border-radius: 15px;
          border: none;
          padding: 10px;
          font-size: 20px;
          line-height: 20px;
          outline: none;
        }

        div {
          input {
            width: 100%;
            accent-color: var(--pink-color);
          }

          .audioDuration{
            width: auto !important;
            background-color:rgba(224, 224, 224, 1) ;
            padding: 8px;
            margin-top: 3px;
            border-radius: 5px;
          }

          label {
            display: block;
          }
        }
      }

      .result {
        .audio-player {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 10px;
          border: none;
          border-radius: 15px;
          background: rgba(224, 224, 224, 1);

          .play-btn,
          .download-btn {
            border: none;
            border-radius: 50%;
            padding: 10px;
            cursor: pointer;
            display: flex;
            justify-content: center;
          }

          .play-btn {
            background-color: var(--pink-color);
          }

          .track-info {
            flex-grow: 1;
            margin: 0 20px;

            .track-title {
              font-size: 18px;
              font-weight: bold;
            }

            .track-time {
              margin-top: 5px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}