:root {
  --p-size: 20px;
  --top-p-size: 25px;
  --main-sections-padding: 25px 100px;
  --text-color: rgba(34, 34, 34, 1);
}

.AboutPage {
  display: flex;
  margin-top: 80px;
  padding: var(--main-sections-padding);
  align-items: center;
  margin-bottom: 80px;
}
.AboutPage .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.AboutPage .left h1 {
  font-size: 80px;
  font-weight: 600;
  color: var(--text-color);
}
.AboutPage .left p {
  font-weight: 300;
  font-size: var(--top-p-size);
  color: var(--text-color);
}
.AboutPage .right {
  display: flex;
  justify-content: center;
}
.AboutPage .right img {
  max-width: 600px;
}

@media screen and (max-width: 1025px) {
  :root {
    --main-sections-padding: 15px 50px;
    --p-size: 1rem;
    --top-p-size: 20px;
  }
  .AboutPage .left h1 {
    font-size: 60px;
    line-height: 60px;
  }
  .AboutPage .right img {
    max-width: 500px;
  }
}/*# sourceMappingURL=about.css.map */