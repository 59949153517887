@media screen and (max-width: 426px) {
  :root {
    --main-sections-padding: 15px 20px;
    --p-size: 1rem;
    --top-p-size: 20px;
  }
  .hamburger {
    position: fixed;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    height: 100vh;
    width: 100%;
  }
  .hamburger .container {
    transition: 0.5s;
    width: 80%;
    height: 100vh;
    padding: 30px;
    background-color: var(--white);
    position: absolute;
    right: -50%;
    animation-name: hamburger;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
  @keyframes hamburger {
    from {
      right: -50%;
    }
    to {
      right: 0;
    }
  }
  .hamburger .container .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hamburger .container .top .left {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .hamburger .container .top .left .login {
    font-weight: 400;
    font-size: var(--p-size);
    line-height: 30px;
  }
  .hamburger .container .top .left .signup {
    padding: 5px 15px;
    background-color: var(--pink-color);
    border-radius: 10px;
    font-size: var(--p-size);
    line-height: 30px;
    color: var(--white);
  }
  .hamburger .container .top .right img {
    cursor: pointer;
  }
  .hamburger .container .bottom {
    padding-top: 50px;
  }
  .hamburger .container .bottom ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  section header .right-head .profile-container {
    grid-template-columns: repeat(2, 1fr);
  }
  section .componies .logos {
    padding: 10px 0;
  }
  section .componies .logos::before, section .componies .logos::after {
    width: 50px;
  }
  section .componies .logos .logos-slide img {
    width: 150px;
  }
  section .about {
    flex-direction: column;
    gap: 20px;
  }
  section .about .left, section .about .right {
    width: 100%;
  }
  section .services-main .bottom .left .text h1 {
    font-size: 1.5rem;
  }
  section .services-main .bottom .left .text p {
    font-size: 0.9rem;
  }
  section .services-main .bottom .right .service__item .text h1 {
    font-size: 1.5rem;
  }
  section .services-main .bottom .right .service__item .text p {
    font-size: 0.9rem;
  }
  section .services-main .bottom .right .service__items {
    flex-direction: column;
  }
  section .services-main .bottom .right .service__items div {
    width: 100%;
  }
  section .advantages .bottom .card .right {
    display: none;
  }
  section .advantages .bottom .card .left {
    width: 100%;
    padding: 20px;
  }
  section .guide {
    flex-direction: column;
    margin-top: 50px;
    gap: 20px;
  }
  section .guide .left, section .guide .right {
    width: 100%;
  }
  section .guide .right iframe {
    height: 250px;
  }
  footer {
    margin-top: 50px;
  }
  footer .top .center {
    display: none;
  }
  footer .top .right img {
    width: 25px;
  }
}/*# sourceMappingURL=425.css.map */