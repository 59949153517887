.prices {
  margin-top: 100px;
  padding: var(--main-sections-padding);
  margin-bottom: 50px;

  .top {
    text-align: center;

    h1 {
      font-weight: 600;
      font-size: 60px;
      color: var(--text-color);
    }

    p {
      font-weight: 300;
      font-size: var(--top-p-size);
      margin-top: 10px;
    }
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    gap: 20px;

    a {
      padding: 10px 30px;
      border-radius: 30px;
      font-weight: 300;
      font-size: var(--p-size);
      color: var(--pink-color);
      border: 1px solid var(--pink-color);

      &.mark {
        color: white;
        background: var(--pink-gradient);
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
    margin-top: 50px;

    .card {
      max-width: 350px;
      width: 350px;
      min-width: 300px;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
      border-radius: 30px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 25px;

      &:nth-child(2) {
        border: 2px solid var(--pink-color);
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      h2 {
        font-weight: 400;
        font-size: 30px;
        text-align: center;
      }

      span {
        text-align: center;
        margin-top: 10px;

        sub {
          text-align: center;
          display: inline-block;
          font-size: var(--top-p-size);
        }

        h1 {
          display: inline-block;
          font-weight: 400;
          font-size: 60px;
          color: var(--pink-color);
          text-align: center;
        }
      }

      hr {
        margin-top: 20px;
      }

      ul {
        margin-top: 20px;

        li {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 5px;
        }
      }

      a {
        padding: 5px 30px;
        border-radius: 30px;
        font-weight: 300;
        font-size: var(--p-size);
        border: 1px solid var(--pink-color);
        margin-top: 25px;
        transition: 0.3s;
        background-color: var(--pink-color);
        color: white;

        &:hover {
          background-color: white;
          color: var(--pink-color);
        }
      }
    }
  }

  @media screen and (max-width: 1025px) {
    :root {
      --main-sections-padding: 15px 50px;
    }

    padding: 15px 50px;
  }
}
