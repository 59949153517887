.services-page {
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  background-image: url(../../../imgs/servicebg.png);
  background-repeat: no-repeat;
  background-size: cover;
  transition: grid-template-columns 0.3s ease;
}
.services-page .menu {
  display: none;
}
.services-page .ServicesNav {
  width: 250px;
  height: 100%;
  padding: 20px;
  border-right: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.8);
  transition: width 0.3s ease;
  position: relative;
}
.services-page .ServicesNav .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.services-page .ServicesNav .top .logo {
  max-width: 150px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.services-page .ServicesNav .top .logosmall {
  display: none;
}
.services-page .ServicesNav .top button {
  border: none;
  outline-style: none;
  display: flex;
  justify-content: center;
  background-color: transparent;
}
.services-page .ServicesNav .top button img {
  width: 24px;
  height: 24px;
}
.services-page .ServicesNav .center {
  margin-top: 10px;
  flex: 1;
  margin-top: 50px;
}
.services-page .ServicesNav .center ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.services-page .ServicesNav .center ul a {
  display: flex;
  align-items: center !important;
  gap: 10px;
  text-decoration: none;
  color: black;
  padding: 5px;
  border-radius: 10px;
}
.services-page .ServicesNav .center ul a img {
  width: 30px;
}
.services-page .ServicesNav .center ul a:hover {
  background-color: #f2f2f2;
}
.services-page .ServicesNav .bottom {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.services-page .ServicesNav .bottom .burger {
  display: none;
}
.services-page .ServicesNav .bottom .language select {
  padding: 7px;
  border-radius: 10px;
  width: 100%;
}
.services-page .ServicesNav .bottom .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}
.services-page .ServicesNav .bottom .container .left img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.services-page .ServicesNav .bottom .container .right button {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

@media screen and (max-width: 1025px) {
  .services-page .ServicesNav {
    width: 60px;
    align-items: center;
    padding: 3px;
    position: fixed;
    padding: 15px 3px;
  }
  .services-page .ServicesNav .top .logosmall {
    display: flex;
    width: 30px;
  }
  .services-page .ServicesNav .top .logo,
  .services-page .ServicesNav .center ul li,
  .services-page .ServicesNav .bottom .cent,
  .services-page .ServicesNav .bottom .right {
    display: none;
  }
  .services-page .right__items .sts {
    padding: 50px 50px 50px 110px;
  }
}
@media screen and (max-width: 769px) {
  .services-page {
    display: block !important;
    height: auto;
  }
  .services-page .ServicesNav {
    width: 100%;
    position: relative;
    border-right: none;
    border-bottom: 1px solid #e2e2e2;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
  }
  .services-page .ServicesNav .center {
    flex: none;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
  .services-page .ServicesNav .center ul {
    flex-direction: row;
  }
  .services-page .ServicesNav .bottom {
    flex-direction: row;
    align-items: center;
  }
  .services-page .ServicesNav .bottom .language select {
    width: auto;
  }
  .services-page .right__items {
    padding-top: 10px;
  }
  .services-page .right__items .sts {
    padding: 30px;
  }
}
@media screen and (max-width: 426px) {
  .services-page .menu {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100vh;
    z-index: 99999;
  }
  .services-page .menu .container {
    width: 70%;
    height: 100vh;
    position: absolute;
    right: 0;
    background-color: #fff;
    padding: 20px;
    transition: 0.5s;
    animation-name: hamburger;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  @keyframes hamburger {
    from {
      right: -50%;
    }
    to {
      right: 0;
    }
  }
  .services-page .menu .container .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .services-page .menu .container .top img {
    width: 130px;
  }
  .services-page .menu .container .top img:last-child {
    width: 24px;
  }
  .services-page .menu .container .center {
    margin-top: 10px;
    flex: 1;
    margin-top: 50px;
  }
  .services-page .menu .container .center ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .services-page .menu .container .center ul a {
    display: flex;
    align-items: center !important;
    gap: 10px;
    text-decoration: none;
    color: black;
    padding: 5px;
    border-radius: 10px;
  }
  .services-page .menu .container .center ul a img {
    width: 30px;
  }
  .services-page .menu .container .center ul a:hover {
    background-color: #f2f2f2;
  }
  .services-page .menu .container .bottom .cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
  }
  .services-page .menu .container .bottom .cont .left img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .services-page .ServicesNav .center, .services-page .ServicesNav .bottom .container {
    display: none;
  }
  .services-page .ServicesNav .bottom .language select {
    padding: 5px;
  }
  .services-page .ServicesNav .bottom .burger {
    display: block;
    width: 30px;
  }
}/*# sourceMappingURL=CustomServices.css.map */