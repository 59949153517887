@media screen and (max-width: 769px) {
  :root {
    --main-sections-padding: 15px 40px;
    --p-size: 1rem;
    --top-p-size: 20px;
  }

  // Hamburger menu styles

  .hamburger {
    position: fixed;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    height: 100vh;
    top: 0;
    width: 100%;
    .container {
      transition: 0.5s;
      width: 80%;
      height: 100vh;
      padding: 30px;
      background-color: var(--white);
      position: absolute;
      right: -50%;
      animation-name: hamburger;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;

      @keyframes hamburger {
        from {
          right: -50%;
        }
        to {
          right: 0;
        }
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          gap: 15px;
          .login {
            font-weight: 400;
            font-size: var(--p-size);
            padding: 0;
          }
          .signup {
            padding: 5px 15px;
            background-color: var(--pink-color);
            border-radius: 10px;
            font-size: var(--p-size);
            line-height: 30px;
            color: var(--white);
          }
          .bot{
            padding: 5px 10px ;
            font-size: var(--p-size);
            border-radius: 10px;
            background-color: rgba(96, 165, 250, 1) ;
            color: white;
            display: flex;
            gap: 5px;
            align-items: center;
            img{
              width: 24px;
            }
          }

          .logout {
            font-size: var(--p-size);
            border-radius: 10px;
            padding: 5px 15px;  
            background-color: red !important;
            color: var(--white);
            outline: none;
            border: none;
            cursor: pointer;
          }
        }
        .right {
          img {
            cursor: pointer;
          }
        }
      }
      .bottom {
        padding-top: 50px;
        ul {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }

  nav {
    .left {
      img {
        width: 150px;
      }
    }
    .center {
      display: none;
    }
    .right {
      .login,
      .signup {
        display: none;
      }
      .logout, .bot{
        display: none;
      }
      
      .menu {
        cursor: pointer;
        display: block !important;
      }
    }
  }

  header{
    flex-direction: column;
    .left-head, .right-head {
      width: 100%;
    }
    .left-head{
      align-items: center;
      text-align: center;
      a{
        padding: 5px 15px;
      }
      h1{
        font-size: 2rem;
      }
    }
  }

  // Componies styles

  .componies {
    .logos {
      padding: 20px 0;
      &::before, &::after{
        width: 100px;
      } 
      .logos-slide {
        img {
          max-width: 175px;
          margin: 0 10px !important  ;
        }
      }
    }
  }

  // Advantages Styles

  .advantages{
    .bottom{
      flex-direction: column;
      fieldset{
        width: 100%;
      }
    }
  }

  .about{
    flex-direction: column;
    gap: 25px;
    text-align: center;
    .left, .right{
      width: 100%;
    }
  }

  // Guide Styles

  .guide {
    flex-direction: column;
    gap: 25px;
    text-align: center;
    .left, .right{
      width: 100%;
      gap: 10px;
      h1{
        font-size: 40px;
        line-height: 40px;
      }
      p{
        font-size: 1rem;
      }

    }
  }

  // Footer styles

  footer {
    .top {
      .left {
        width: 136px;
        img {
          width: 100%;
        }
      }
      .right {
        img {
          width: 25px;
        }
      }
    }
  }
}

