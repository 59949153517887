@media screen and (max-width: 769px) {
  :root {
    --main-sections-padding: 15px 40px;
    --p-size: 1rem;
    --top-p-size: 20px;
  }
  .hamburger {
    position: fixed;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    height: 100vh;
    top: 0;
    width: 100%;
  }
  .hamburger .container {
    transition: 0.5s;
    width: 80%;
    height: 100vh;
    padding: 30px;
    background-color: var(--white);
    position: absolute;
    right: -50%;
    animation-name: hamburger;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
  @keyframes hamburger {
    from {
      right: -50%;
    }
    to {
      right: 0;
    }
  }
  .hamburger .container .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hamburger .container .top .left {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .hamburger .container .top .left .login {
    font-weight: 400;
    font-size: var(--p-size);
    padding: 0;
  }
  .hamburger .container .top .left .signup {
    padding: 5px 15px;
    background-color: var(--pink-color);
    border-radius: 10px;
    font-size: var(--p-size);
    line-height: 30px;
    color: var(--white);
  }
  .hamburger .container .top .left .bot {
    padding: 5px 10px;
    font-size: var(--p-size);
    border-radius: 10px;
    background-color: rgb(96, 165, 250);
    color: white;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .hamburger .container .top .left .bot img {
    width: 24px;
  }
  .hamburger .container .top .left .logout {
    font-size: var(--p-size);
    border-radius: 10px;
    padding: 5px 15px;
    background-color: red !important;
    color: var(--white);
    outline: none;
    border: none;
    cursor: pointer;
  }
  .hamburger .container .top .right img {
    cursor: pointer;
  }
  .hamburger .container .bottom {
    padding-top: 50px;
  }
  .hamburger .container .bottom ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  nav .left img {
    width: 150px;
  }
  nav .center {
    display: none;
  }
  nav .right .login,
  nav .right .signup {
    display: none;
  }
  nav .right .logout, nav .right .bot {
    display: none;
  }
  nav .right .menu {
    cursor: pointer;
    display: block !important;
  }
  header {
    flex-direction: column;
  }
  header .left-head, header .right-head {
    width: 100%;
  }
  header .left-head {
    align-items: center;
    text-align: center;
  }
  header .left-head a {
    padding: 5px 15px;
  }
  header .left-head h1 {
    font-size: 2rem;
  }
  .componies .logos {
    padding: 20px 0;
  }
  .componies .logos::before, .componies .logos::after {
    width: 100px;
  }
  .componies .logos .logos-slide img {
    max-width: 175px;
    margin: 0 10px !important;
  }
  .advantages .bottom {
    flex-direction: column;
  }
  .advantages .bottom fieldset {
    width: 100%;
  }
  .about {
    flex-direction: column;
    gap: 25px;
    text-align: center;
  }
  .about .left, .about .right {
    width: 100%;
  }
  .guide {
    flex-direction: column;
    gap: 25px;
    text-align: center;
  }
  .guide .left, .guide .right {
    width: 100%;
    gap: 10px;
  }
  .guide .left h1, .guide .right h1 {
    font-size: 40px;
    line-height: 40px;
  }
  .guide .left p, .guide .right p {
    font-size: 1rem;
  }
  footer .top .left {
    width: 136px;
  }
  footer .top .left img {
    width: 100%;
  }
  footer .top .right img {
    width: 25px;
  }
}/*# sourceMappingURL=768.css.map */