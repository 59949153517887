.partners {
    background-color: #101010;
}

.partners .top {
    text-align: center;
}

.partners .top h1 {
    font-size: 39px;
    font-weight: 500;
    background: linear-gradient(90deg, #9F9F9F 0%, #FFFFFF 51.5%, #999999 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
}

.partners .logos .logos-slide:last-child {
    display: none;
}

.partners .logos .logos-slide {
    padding: 25px 100px;
    display: flex;
    gap: 25px;
    justify-content: space-between;
}

.partners .logos .logos-slide img {
    max-width: 220px;
    border: 1px solid #FFFFFF33;
    padding: 20px;
}

@media screen and (max-width: 1401px) {
    .partners .logos .logos-slide {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (max-width:1223px) {
    .partners .bottom .logos-slide {
        padding: 25px 50px;
    }
}

@media screen and (max-width: 769px) {
    .partners .logos {
        overflow: hidden;
        display: flex;
        gap: 0px;
    }

    .partners .logos .logos-slide {
        animation: 15s logos infinite linear;
        flex-wrap: nowrap;
        justify-content: start;
        gap: 0;
        padding: 25px 0;
    }

    .partners .logos .logos-slide:last-child {
        display: flex;
        padding-left: 0;
    }

    .partners .logos .logos-slide img{
        margin: 0 20px;
    }

    @keyframes logos {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-100%);
        }
    }
}