.trynow{
    padding: 25px 100px;
    background-color: #101010;
    display: flex;
    justify-content: center;
}

.trynow .container {
    background: 
      url(../../imgs/bgwavewhite.png) no-repeat center, 
      linear-gradient(226.62deg, #F66A1D 1.33%, #AF0C89 46.42%, #1B3CA3 98.67%);
    background-size: cover;
    width: 794px;
    border-radius: 30px;
    text-align: center;
    padding: 50px;
}


.trynow .container h1{
    font-weight: 500;
    font-size: 39px;
    line-height: 58.5px;
    background: linear-gradient(90deg, #9F9F9F 0%, #FFFFFF 51.5%, #999999 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.trynow .container p{
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: #FFFFFF;
}

.trynow .container div{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.trynow .container div a{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 7px 25px;
    border-radius: 70px;
}

.trynow .container div a:first-child{
    background: #FFFFFF;
    color: #303030;
}

.trynow .container div a:last-child{
    border: 0.5px solid #FFFFFF;
    color: #fff;
}