.about-zabon{
    padding: 50px 100px;
    background-color: #101010;
}

.about-zabon .container {
    border-radius: 30px;
    background-color: #131313;
    overflow: hidden;
}

.about-zabon .container .top{
    padding: 50px;
}

.about-zabon .container .top h1{
    font-size: 39px;
    font-weight: 500;
    background: linear-gradient(90deg, #9F9F9F 0%, #FFFFFF 51.5%, #999999 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
}

.about-zabon .container .bottom{
    display: flex;
    gap: 70px;
    padding-right: 100px;
    text-align: justify;
    font-size: 400;
    font-size: 16px;
    line-height: 22.4px;
    color: #fff;
}

.about-zabon .container .bottom .right{
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: start;
}

.about-zabon .container .bottom .right a{
    color: #FFFFFF;
    padding:8px 30px 8px 30px;
    border-radius: 70px;
    border: 0.5px solid #FFFFFF;
    background: linear-gradient(94.59deg, rgba(255, 255, 255, 0.2) 0.56%, rgba(153, 153, 153, 0) 99.44%);
    font-weight: 500;
}

@media screen and (max-width:1223px) {
    .about-zabon {
        padding: 25px 50px;
    }
} 

@media screen and (max-width:1025px) {
    .about-zabon .container .bottom{
        padding-right: 50px;
    }
    .about-zabon .container .top{
        padding: 40px;
    }
}

@media screen and (max-width: 769px) {
    .about-zabon .container .bottom{
        flex-direction: column-reverse;
        padding: 0;
    }

    .about-zabon .container .bottom .right{
        padding-inline: 40px;
    }
}