* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
  // border: 1px solid red;
}

:root {
  --white: white;
  --text-color: rgba(34, 34, 34, 1);
  --section-top-p: rgba(109, 109, 109, 1);
  --pink-color: rgba(255, 43, 158, 1);
  --header-gradient-h1: linear-gradient(
    106.7deg,
    #222222 12.31%,
    #5b5b5b 87.69%
  );
  --blue-btn-gradient: linear-gradient(
    92.71deg,
    #5a56ff 11.79%,
    #8a88ff 88.21%
  );
  --pink-gradient: linear-gradient(87.99deg, #ff5cb5 24.48%, #ff8bca 75.52%);
  --service-cards-gradient: linear-gradient(90deg, #5a56ff 0%, #8a88ff 100%);

  --p-size: 20px;
  --top-p-size: 25px;
  --main-sections-padding: 25px 100px;
}

header,
.componies,
.about,
.services-main,
.advantages,
.guide {
  overflow: hidden;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

.hamburger {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--pink-color);
  border-radius: 10px;
}

.bgwave{
  position: absolute;
  left: 0;
}

nav {
  padding: var(--main-sections-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  backdrop-filter: blur(10px);
  margin: 0 auto;
  .center {
    ul {
      display: flex;
      gap: 30px;
      li {
        a {
          font-weight: 400;
          font-size: var(--p-size);
          line-height: 30px;
          position: relative;
          color: var(--text-color);
          transition: 0.5s;
          &::before {
            content: "";
            width: 0;
            height: 2px;
            border-radius: 2px;
            background-color: var(--pink-color);
            position: absolute;
            bottom: -0.25rem;
            left: 50%;
            transition: width 0.4s, left 0.4s;
          }
          &:hover {
            color: var(--pink-color);
          }
          &:hover::before {
            width: 100%;
            left: 0;
          }
        }
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 25px;
    select {
      padding: 10px;
      border-radius: 50pc;
      border: 1px solid rgba(203, 203, 203, 1);
      font-size: var(--p-size);
      font-weight: 400;
      line-height: 30px;
    }
    .login {
      font-weight: 400;
      font-size: var(--p-size);
      line-height: 30px;
    }
    .signup {
      padding: 10px 25px 10px 25px;
      background-color: var(--pink-color);
      border-radius: 10px;
      font-size: var(--p-size);
      line-height: 30px;
      color: var(--white);
    }
    .logout {
      font-size: var(--p-size);
      border-radius: 10px;
      padding: 10px 25px 10px 25px;
      background-color: red !important;
      color: var(--white);
      outline: none;
      border: none;
      cursor: pointer;
    }
    .bot{
      padding: 10px 25px ;
      font-size: var(--p-size);
      border-radius: 10px;
      background-color: rgba(96, 165, 250, 1) ;
      color: white;
      display: flex;
      gap: 5px;
      align-items: center;
      img{
        width: 24px;
      }
    }
    .menu{
      display: none;
    }
  }
}

header {
  margin-top: 100px;
  padding: var(--main-sections-padding);
  display: flex;
  gap: 20px;

  .left-head {
    width: 50%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 25px;
    h2{
      font-size: 1.25rem;
      font-weight: 400;
    }
    h1{
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 1.25em;
    }
    a{
      padding: 10px 25px 10px 25px;
      background-color: var(--pink-color);
      border-radius: 10px;
      font-size: var(--p-size);
      line-height: 30px;
      color: var(--white);
    }
  }
  .right-head {
    width: 50%;
  }
}

section {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
}

// Componies styles

.componies {
  width: 100%;
  padding: 50px 0;
  .top {
    p {
      font-weight: 300;
      font-size: var(--top-p-size);
      line-height: 27.5px;
      text-align: center;
      color: var(--section-top-p);
    }
  }
  .logos {
    overflow: hidden;
    padding: 40px 0;
    white-space: nowrap;
    position: relative;
    display: flex;

    &::before {
      left: 0;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0),
        var(--white)
      ) !important;
    }

    .logos-slide {
      animation: 20s slide infinite linear;
      display: flex;
      align-items: center;

      img {
        margin: 0 30px;
        max-width: 210px;
      }
    }
  }
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

// About Styles

.about {
  padding: var(--main-sections-padding);
  display: flex;
  justify-content: space-between;
  .left {
    width: 40%;
    align-content: center;
    h1 {
      font-weight: 600;
      font-size: 60px;
      line-height: 60px;
      span {
        color: var(--pink-color);
      }
    }
    p {
      margin-top: 20px;
      font-weight: 300;
      font-size: var(--p-size);
      line-height: 27.5px;
      color: var(--section-top-p);
    }
  }

  .right {
    width: 60%;
    display: flex;
    justify-content: end;
    img {
      width: 100%;
    }
  }
}

// Services Styles

.services-main {
  margin-top: 150px;
  
  .top {
    p {
      font-weight: 300;
      font-size: var(--top-p-size);
      line-height: 27.5px;
      text-align: center;
      color: var(--section-top-p);
    }
  }
  
  .bottom {
    padding: var(--main-sections-padding);
    margin-top: 50px;
    display: flex;
    gap: 40px;
    align-items: flex-end;
    height: 550px;
    position: relative;

    video, video source{
      position: absolute;
      bottom: -70px;
      left: 0;
      z-index: -10;
    }

    .left,
    .right {
      width: 50%;
      height: 100%;
    }

    .left {
      border-radius: 20px;
      background-image: url("imgs/voiceclone.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      transition: 0.5s;

      &:hover {
        transform: scale(1.05);
      }

      .text {
        color: var(--white);
        display: flex;
        flex-direction: column;
        gap: 15px;

        h1 {
          font-size: 2rem;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 25px;

      .service__item,
      .service__items {
        height: 50%;
        width: 100%;
      }

      .service__item {
        border-radius: 20px;
        padding: 30px;
        background-image: url(imgs/speechtotext.jpg);
        transition: 0.5s;

        &:hover {
          transform: scale(1.05);
        }

        .text {
          color: var(--white);
          display: flex;
          flex-direction: column;
          gap: 15px;

          h1 {
            font-size: 2rem;
          }
        }
      }

      .service__items {
        display: flex;
        gap: 25px;

        div {
          border-radius: 20px;
          padding: 30px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: var(--white);
          transition: 0.5s;
          width: 50%;

          &:hover {
            transform: scale(1.05);
          }

          &:first-child {
            background-color: var(--pink-color);
          }

          &:last-child {
            background-color: #ffcc00;
          }
        }
      }
    }
  }
}

// Advantages styles

.advantages {
  padding: var(--main-sections-padding);
  margin-top: 100px;
  .top {
    p {
      font-weight: 300;
      font-size: var(--top-p-size);
      line-height: 27.5px;
      text-align: center;
      color: var(--section-top-p);
    }
  }
  .bottom {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    fieldset {
      display: inline-block;
      padding-inline: 50px;
      padding-top: 25px;
      border-radius: 10px;
      padding-bottom: 25px;
      width: 50%;
      border: solid var(--pink-color);
      legend {
        font-size: 2rem;
        padding: 10px;
      }
      li {
        list-style: disc;
        font-weight: 300;
        font-size: 25px;
        line-height: 27.5px;
        color: var(--section-top-p);
        margin-bottom: 10px;
      }
    }
  }
}

// Guide Styles

.guide {
  display: flex;
  justify-content: space-between;
  padding: var(--main-sections-padding);
  margin-top: 150px;
  margin-bottom: 100px;

  .left {
    width: 37.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    h1 {
      font-weight: 600;
      font-size: 80px;
      line-height: 80px;
    }
    p {
      font-weight: 400;
      font-size: var(--top-p-size);
      line-height: 27.5px;
      color: var(--section-top-p);
    }
  }
  .right {
    width: 57.5%;
    iframe {
      border-radius: 30px;
    }
  }
}

// footer styles

footer {
  background-color: var(--text-color);
  padding: var(--main-sections-padding);
  position: relative;
  z-index: 999;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      img {
        max-width: 154px;
      }
    }
    .center {
      ul {
        display: flex;
        gap: 20px;
        a {
          color: var(--white);
          font-weight: 400;
          font-size: var(--p-size);
          line-height: 30px;
        }
      }
    }
    .right {
      ul {
        display: flex;
        gap: 10px;
      }
    }
  }
  .bottom {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    .left {
      p {
        font-weight: 250;
        font-size: var(--p-size);
        line-height: 30px;
        color: var(--section-top-p);
        span {
          color: var(--white);
        }
      }
    }
    .right {
      a {
        font-weight: 500;
        font-size: var(--p-size);
        line-height: 30px;
        color: var(--white);
      }
    }
  }
}

.telegram-notification {
  max-width: 320px;
  border-width: 1px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
  padding: 1rem;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 555;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
    .close{
      cursor: pointer;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 9999px;
      background-color: rgba(96, 165, 250, 1);
      padding: 0.5rem;
    }
    
    .icon svg {
      height: 1rem;
      width: 1rem;
    }
    
    .alert {
      font-weight: 600;
      flex: 1;
      color: rgba(107, 114, 128, 1);
    }
    
  }
  .message {
    margin-top: 1rem;
    color: rgba(107, 114, 128, 1);
  }
  .actions {
    margin-top: 1.5rem;
  }
  .actions a {
    text-decoration: none;
  }
  
  .mark-as-read,
  .read {
    display: inline-block;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.75rem 1.25rem;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
  }
  
  .read {
    background-color: rgba(59, 130, 246, 1);
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
  }
}