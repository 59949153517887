.sts {
  width: 100%;
  height: 100vh;
  background-repeat: repeat;
  background-size: cover;
  padding: 100px;
  padding-top: 120px;
  justify-content: center;
  background-image: url(../imgs/servicebg.png);
}
.sts .container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 2420px;
  margin: 0 auto;
}
.sts .top h1 {
  font-weight: 600;
  font-size: 60px;
  line-height: 60px;
}
.sts .center {
  display: flex;
  gap: 10px;
}
.sts .center .mark {
  background: var(--pink-gradient);
  color: var(--white);
}
.sts .center a {
  padding: 10px 30px;
  background-color: var(--white);
  border-radius: 10px;
  font-weight: 600;
  font-size: var(--p-size);
  transition: 0.5s;
}
.sts .center a:hover {
  background: var(--pink-gradient);
  color: var(--white);
}
.sts .tools {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.sts .tools .tool {
  width: 33%;
  height: 350px;
  background-color: var(--white);
  border-radius: 20px;
  padding: 25px;
}
.sts .tools .tool .upload_top,
.sts .tools .tool .language-top,
.sts .tools .tool .result-top,
.sts .tools .tool .text-top,
.sts .tools .tool .voice-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sts .tools .tool .upload_top p,
.sts .tools .tool .language-top p,
.sts .tools .tool .result-top p,
.sts .tools .tool .text-top p,
.sts .tools .tool .voice-top p {
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
}
.sts .tools .tool .upload_top button,
.sts .tools .tool .language-top button,
.sts .tools .tool .result-top button,
.sts .tools .tool .text-top button,
.sts .tools .tool .voice-top button {
  padding: 10px 25px;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  background: var(--pink-gradient);
  font-size: 20px;
  line-height: 20px;
  color: var(--white);
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.sts .tools .tool .upload_top .spinner,
.sts .tools .tool .language-top .spinner,
.sts .tools .tool .result-top .spinner,
.sts .tools .tool .text-top .spinner,
.sts .tools .tool .voice-top .spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}
.sts .tools .tool .upload,
.sts .tools .tool .language,
.sts .tools .tool .result,
.sts .tools .tool .text,
.sts .tools .tool .voice {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}
.sts .tools .tool .upload-bottom {
  width: 100%;
  height: 100%;
}
.sts .tools .tool .upload-bottom .file-upload-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sts .tools .tool .upload-bottom .file-upload-label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgb(224, 224, 224);
  padding: 30px 70px;
  border-radius: 15px;
  border: 2px dashed rgba(0, 0, 0, 0.5);
}
.sts .tools .tool .upload-bottom .file-upload-label input {
  display: none;
}
.sts .tools .tool .upload-bottom .file-upload-label svg {
  height: 50px;
  fill: var(--pink-color);
  margin-bottom: 20px;
}
.sts .tools .tool .upload-bottom .file-upload-design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.sts .tools .tool .upload-bottom .browse-button {
  background-color: var(--pink-color);
  padding: 5px 15px;
  border-radius: 10px;
  color: var(--white);
  transition: all 0.3s;
}
.sts .tools .tool .language-bottom,
.sts .tools .tool .voice-bottom {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sts .tools .tool .language-bottom select,
.sts .tools .tool .voice-bottom select {
  width: 100%;
  background-color: rgb(224, 224, 224);
  border-radius: 15px;
  border: none;
  padding: 10px;
  font-size: 20px;
  line-height: 20px;
  outline: none;
}
.sts .tools .tool .language-bottom div input,
.sts .tools .tool .voice-bottom div input {
  width: 100%;
  accent-color: var(--pink-color);
}
.sts .tools .tool .language-bottom div .audioDuration,
.sts .tools .tool .voice-bottom div .audioDuration {
  width: auto !important;
  background-color: rgb(224, 224, 224);
  padding: 8px;
  margin-top: 3px;
  border-radius: 5px;
}
.sts .tools .tool .language-bottom div label,
.sts .tools .tool .voice-bottom div label {
  display: block;
}
.sts .tools .tool .result .audio-player {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 15px;
  background: rgb(224, 224, 224);
}
.sts .tools .tool .result .audio-player .play-btn,
.sts .tools .tool .result .audio-player .download-btn {
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
.sts .tools .tool .result .audio-player .play-btn {
  background-color: var(--pink-color);
}
.sts .tools .tool .result .audio-player .track-info {
  flex-grow: 1;
  margin: 0 20px;
}
.sts .tools .tool .result .audio-player .track-info .track-title {
  font-size: 18px;
  font-weight: bold;
}
.sts .tools .tool .result .audio-player .track-info .track-time {
  margin-top: 5px;
  font-size: 14px;
}/*# sourceMappingURL=sts.css.map */