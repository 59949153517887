.tools {
  .file,
  .result-text {
    height: 350px;
    background-color: var(--white);
    border-radius: 20px;
    padding: 25px;
  }
  .file {
    width: 33%;
    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 100%;
    }
  }
  .file-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 25px;
      line-height: 25px;
      font-weight: 500;
    }
    button {
      padding: 10px 25px;
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      background: var(--pink-gradient);
      font-size: 20px;
      line-height: 20px;
      color: var(--white);
    }
  }
  .file-bottom {
    height: 100%;
    .file-upload-form {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .file-upload-label {
      input {
        display: none;
      }

      svg {
        height: 50px;
        fill: var(--pink-color);
        margin-bottom: 20px;
      }
      width: 100%;
      height: 100%;
      cursor: pointer;
      background-color: rgba(224, 224, 224, 1);
      padding: 30px 70px;
      border-radius: 15px;
      border: 2px dashed rgba(0, 0, 0, 0.5);
    }

    .file-upload-design {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }

    .browse-button {
      background-color: var(--pink-color);
      padding: 5px 15px;
      border-radius: 10px;
      color: var(--white);
      transition: all 0.3s;
    }
  }
  .result-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 66%;
    .result-text-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 25px;
        line-height: 25px;
        font-weight: 500;
      }

      .copy {
        --button-bg: #353434;
        --button-hover-bg: #464646;
        --button-text-color: #cccccc;
        --button-hover-text-color: #8bb9fe;
        --button-border-radius: 10px;
        --button-diameter: 36px;
        --button-outline-width: 1px;
        --button-outline-color: rgb(141, 141, 141);
        --tooltip-bg: #f4f3f3;
        --toolptip-border-radius: 4px;
        --tooltip-font-family: Menlo, Roboto Mono, monospace;
        --tooltip-font-size: 12px;
        --tootip-text-color: rgb(50, 50, 50);
        --tooltip-padding-x: 7px;
        --tooltip-padding-y: 7px;
        --tooltip-offset: 8px;
      }

      .copy {
        box-sizing: border-box;
        width: var(--button-diameter);
        height: var(--button-diameter);
        border-radius: var(--button-border-radius);
        background: var(--pink-gradient);
        color: var(--white);
        border: none;
        cursor: pointer;
        position: relative;
        outline: none;
      }

      .tooltip {
        position: absolute;
        opacity: 0;
        visibility: 0;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        font: var(--tooltip-font-size) var(--tooltip-font-family);
        color: var(--tootip-text-color);
        background: var(--tooltip-bg);
        padding: var(--tooltip-padding-y) var(--tooltip-padding-x);
        border-radius: var(--toolptip-border-radius);
        pointer-events: none;
        transition: all var(--tooltip-transition-duration)
          cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }

      .tooltip::before {
        content: attr(data-text-initial);
      }

      .tooltip::after {
        content: "";
        position: absolute;
        bottom: calc(var(--tooltip-padding-y) / 2 * -1);
        width: var(--tooltip-padding-y);
        height: var(--tooltip-padding-y);
        background: inherit;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        z-index: -999;
        pointer-events: none;
      }

      .copy svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .checkmark {
        display: none;
      }

      .copy:hover .tooltip,
      .copy:focus:not(:focus-visible) .tooltip {
        opacity: 1;
        visibility: visible;
        top: calc((100% + var(--tooltip-offset)) * -1);
      }

      .copy:focus:not(:focus-visible) .tooltip::before {
        content: attr(data-text-end);
      }

      .copy:focus:not(:focus-visible) .clipboard {
        display: none;
      }

      .copy:focus:not(:focus-visible) .checkmark {
        display: block;
      }

      .copy:hover,
      .copy:focus {
        background-color: var(--button-hover-bg);
      }

      .copy:active {
        outline: var(--button-outline-width) solid var(--button-outline-color);
      }
    }
    .result-text-bottom{
      height: 100%;
      .container{
        width: 100%;
        max-height: 80%;
        background-color: rgba(224, 224, 224, 1);
        border-radius: 20px;
        padding: 25px;
        overflow: scroll;
        &::-webkit-scrollbar{
          display: none;  
        }
      }
    }
  }
}
