.advantage{
    background-color: #101010;
    padding: 25px 100px;
}

.advantage .top{
    text-align: center;
}

.advantage .top h1{
    font-size: 39px;
    font-weight: 500;
    background: linear-gradient(90deg, #9F9F9F 0%, #FFFFFF 51.5%, #999999 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
}

.advantage .bottom{
    display: flex;
    gap: 25px;
    margin-top: 25px;
}

.advantage .bottom .card{
    padding: 25px;
    background: #191919;
    border-radius: 20px;
    min-height: 387px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.advantage .bottom .card span{
    font-size: 25px;
    font-weight: 500;
    background: linear-gradient(90deg, #9F9F9F 0%, #FFFFFF 51.5%, #999999 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.advantage .bottom .card h2{
    font-weight: 500;
    font-size: 25px;
    line-height: 27.5px;
    background: linear-gradient(90deg, #9F9F9F 0%, #FFFFFF 51.5%, #999999 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-top: 10px;
}

.advantage .bottom .card hr{
    border: 1px solid #FFFFFF33;
}

.advantage .bottom .card p{
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
    color: #FFFFFF;
    margin-top: 10px;
}

@media screen and (max-width:1223px) {
    .advantage {
        padding: 25px 50px;
    }
} 

@media screen and (max-width:768px) {
    .advantage .bottom{
        flex-wrap: wrap;
        justify-content: center;
    }

    .advantage .bottom .card{
        max-width: 300px;
    }
}