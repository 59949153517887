* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}

:root {
  --text-color:rgba(48, 48, 48, 1);
  --submit-color:rgba(90, 86, 255, 1);
}

a {
  text-decoration: none;
  color: black;
}

input {
  outline: none;
  border: none;
}

.registration {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: url(../imgs/loginbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}
.registration .login {
  max-width: 500px;
  width: 500px;
  background: linear-gradient(147.51deg, rgba(255, 255, 255, 0.1) 1.62%, rgba(255, 255, 255, 0.4) 98.38%);
  padding: 50px;
  border-radius: 30px;
  border: 2px solid;
  border-image-source: linear-gradient(145.7deg, rgba(126, 145, 255, 0.7) 0.36%, rgba(249, 178, 255, 0.7) 99.64%);
}
.registration .login h1 {
  font-size: 40px;
  color: var(--text-color);
}
.registration .login form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 25px;
}
.registration .login form label, .registration .login form .item {
  display: block;
  color: var(--text-color);
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.registration .login form .input {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  padding: 15px;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
}
.registration .login form .submit {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  background: var(--submit-color);
  color: white;
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  cursor: pointer;
}
.registration .login form .two {
  text-align: center;
}
.registration .login form .google a {
  border-radius: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--text-color);
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  background-color: #fff;
  height: 50px;
}

@media screen and (max-width: 426px) {
  .container .login {
    padding: 30px;
  }
}/*# sourceMappingURL=login.css.map */