.profile-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  justify-content: center;

  .profile-card {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    color: white;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:hover {
      .profile-details {
        padding-bottom: 20px;
      }
    }

    .profile-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    .profile-details {
      transition: 0.3s;
      z-index: 2;
      padding: 10px;
      background: rgba(95, 95, 95, 0.19);
      border-top-right-radius: 20px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(16.8px);
      -webkit-backdrop-filter: blur(16.8px);
      border: 1px solid rgba(95, 95, 95, 0.3);
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        margin: 0;
        font-size: 1rem;
      }

      p {
        margin: 0;
        font-size: 0.75rem;
      }

      .profile-button {
        bottom: 10px;
        right: 10px;
        z-index: 3;
        background: transparent;
        border: none;
        color: white;
        padding: 5px 10px;
        border-radius: 50%;
        cursor: pointer;
        font-size: 18px;

        img {
          width: 20px;
        }
      }
    }
  }
}
