@media screen and (max-width: 1024px) and (min-width: 769px) {
  :root {
    --main-sections-padding: 15px 50px;
    --p-size: 1rem;
    --top-p-size: 20px;
  }

  // Header Styles

  nav {
    .left {
      img {
        width: 150px;
      }
    }

    .center {
      ul {
        gap: 15px;
      }
    }

    .right {
      gap: 15px;

      select{
        padding: 5px;
      }

      .signup, .bot {
        padding: 5px 10px;
      }
    }
  }

  section {

    header{
      margin-top: 75px;
      .left-head{
        h1{
          font-size: 2.5rem;
        }
      }
      .right-head{
        .profile-container .profile-card{
          height: 200px;
        }
      }
    }

    // Componies Styles

    .componies {
      .logos {
        padding: 20px 0;

        .logos-slide {
          img {
            max-width: 125px;
            margin: 0 20px;
          }
        }
      }
    }

    // Services Styles

    .services-main {
      .bottom {
        height: 450px;

        .left {
          .text {
            h1 {
              font-size: 1.5rem;
            }

            p {
              font-size: 0.9rem;
            }
          }
        }

        .right {
          .service__item {
            .text {
              h1 {
                font-size: 1.5rem;
              }

              p {
                font-size: 0.9rem;
              }
            }
          }

          .service__items {
            div {
              h1 {
                font-size: 1.5rem;
              }
            }
          }
        }
      }
    }

    // Advantages styles

    .advantages {
      .bottom {
        .card {
          gap: 50px;

          .left {
            h1 {
              font-size: 40px;
            }
          }

          .right {
            img {
              width: calc(420px / 1.2);
            }
          }
        }
      }
    }

    // Guide Styles

    .guide {
      .left {
        h1 {
          font-size: 60px;
        }
      }

      .right {
        iframe {
          height: 300px;
        }
      }
    }
  }

  //  Footer Styles

  footer {
    .top {
      .left {
        img {
          width: 150px;
        }
      }

      .right {
        ul {
          li {
            img {
              width: 25px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1341px) {
  :root {
    --main-sections-padding: 15px 50px;
  }

  header {
    padding: var(--main-sections-padding);
  }
}

@media screen and (max-width: 1024px) and (min-width: 425px) {

  //  About Styles
  .about {
    .left {
      h1 {
        font-size: 40px !important;
        line-height: 40px !important;
      }

      p {
        line-height: 20px !important;
      }
    }
  }
}


@media screen and (max-width: 901px) {
  .services-main {
    .bottom {
      height: 1000px !important;
      flex-direction: column;

      .left,
      .right {
        width: 100%;
        height: 50% !important;
      }

      .left {
        height: 500px !important;
      }
    }
  }
}