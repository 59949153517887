:root {
  --top-p-size: 25px;
  --main-sections-padding: 25px 100px;
  --p-size: 20px;

  --text-color: rgba(34, 34, 34, 1);
  --pink-color: rgba(255, 43, 158, 1);
}

.team {
  padding: var(--main-sections-padding);
  margin-bottom: 50px;
  .hidden {
    display: none;
  }

  .loading {
    text-align: center;
    font-size: 24px;
    margin-top: 50px;
  }

  .top {
    h1 {
      font-weight: 600;
      font-size: 60px;
      color: var(--text-color);
    }
    p {
      margin-top: 20px;
      font-weight: 300;
      font-size: var(--top-p-size);
    }
  }
  .bottom {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 50px;
    .card {
      width: 240px;
      max-height: 375px;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      .cardTop {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        overflow: hidden;
        height: 250px;
        background-size: cover;
      }
      .cardBottom {
        height: 29%;
        padding: 20px;
        h2 {
          font-size: var(--p-size);
          font-weight: 500;
        }
        p {
          font-weight: 300;
          font-size: 13px;
        }
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  :root {
    --main-sections-padding: 15px 50px;
    --p-size: 1rem;
    --top-p-size: 20px;
  }

  .AboutPage {
    .left {
      h1 {
        font-size: 60px;
        line-height: 60px;
      }
    }
    .right {
      img {
        max-width: 500px;
      }
    }
  }
}

@media screen and (max-width: 832px) {
  .AboutPage {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .team {
    .bottom {
      justify-content: space-between;
      .card {
        width: 230px;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .team {
    .bottom {
      .card {
        width: 220px;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .team {
    .bottom {
      .card {
        width: 210px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  :root {
    --main-sections-padding: 15px 40px;
  }
  .team {
    .bottom {
      .card {
        width: 200px;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .AboutPage {
    .right {
      display: none;
    }
  }
}

@media screen and (max-width: 500px) {
  :root {
    --main-sections-padding: 15px 30px;
  }
}

@media screen and (max-width: 480px) {
  :root {
    --main-sections-padding: 15px 20px;
  }
}

@media screen and (max-width: 460px) {
  .team {
    .bottom {
      .card {
        max-width: 100%;
        width: 100%;
        max-height: 450px;
        .cardTop {
          height: 350px;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
}
