@media screen and (max-width: 426px) {
  :root {
    --main-sections-padding: 15px 20px;
    --p-size: 1rem;
    --top-p-size: 20px;
  }

  .hamburger {
    position: fixed;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
    height: 100vh;
    width: 100%;
    .container {
      transition: 0.5s;
      width: 80%;
      height: 100vh;
      padding: 30px;
      background-color: var(--white);
      position: absolute;
      right: -50%;
      animation-name: hamburger;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;

      @keyframes hamburger {
        from {
          right: -50%;
        }
        to {
          right: 0;
        }
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          display: flex;
          align-items: center;
          gap: 15px;
          .login {
            font-weight: 400;
            font-size: var(--p-size);
            line-height: 30px;
          }
          .signup {
            padding: 5px 15px;
            background-color: var(--pink-color);
            border-radius: 10px;
            font-size: var(--p-size);
            line-height: 30px;
            color: var(--white);
          }
        }
        .right {
          img {
            cursor: pointer;
          }
        }
      }
      .bottom {
        padding-top: 50px;
        ul {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
  }

  section {

    header{
      .right-head{
        .profile-container{
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    // Componies styles
     
    .componies{
      .logos{
        padding: 10px 0;
        &::before, &::after{
          width: 50px;
        }
        .logos-slide{
          img{
            width: 150px;
          }
        }
      }
    }

    // About Styles

    .about{
      flex-direction: column;
      gap: 20px;
      .left, .right{
        width: 100%;
      }
    }

    // Services styles

    .services-main{
      .bottom{
        .left{
          .text{
            h1{
              font-size: 1.5rem;
            }
            p{
              font-size: 0.9rem;
            }
          }
        }
        .right{
          .service__item{
            .text{
              h1{
                font-size: 1.5rem;
              }
              p{
                font-size: 0.9rem;
              }
            }
          }
          .service__items{
            flex-direction: column;
            div{
              width: 100%;
            }
          }
        }
      }
    }

    // Advantages styles

    .advantages{
      .bottom{
        .card{
          .right{
            display: none;
          }
          .left{
            width: 100%;
            padding: 20px;
          }
        }
      }
    }

    // guide styles

    .guide{
      flex-direction: column;
      margin-top: 50px;
      gap: 20px;
      .left, .right{
        width: 100%;
      }
      .right{
        iframe{
          height: 250px;
        }
      }
    }

  }
  // footer styles

  footer{
    margin-top: 50px;
    .top{
      .center{
        display: none;
      }
      .right{
        img{
          width: 25px;
        }
      }
    }
  }
}
