* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}

:root {
  --white: white;
  --text-color: rgba(34, 34, 34, 1);
  --section-top-p: rgba(109, 109, 109, 1);
  --pink-color: rgba(255, 43, 158, 1);
  --header-gradient-h1: linear-gradient(
    106.7deg,
    #222222 12.31%,
    #5b5b5b 87.69%
  );
  --blue-btn-gradient: linear-gradient(
    92.71deg,
    #5a56ff 11.79%,
    #8a88ff 88.21%
  );
  --pink-gradient: linear-gradient(87.99deg, #ff5cb5 24.48%, #ff8bca 75.52%);
  --service-cards-gradient: linear-gradient(90deg, #5a56ff 0%, #8a88ff 100%);
  --p-size: 20px;
  --top-p-size: 25px;
  --main-sections-padding: 25px 100px;
}

header,
.componies,
.about,
.services-main,
.advantages,
.guide {
  overflow: hidden;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

.hamburger {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--pink-color);
  border-radius: 10px;
}

.bgwave {
  position: absolute;
  left: 0;
}

nav {
  padding: var(--main-sections-padding);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  margin: 0 auto;
}
nav .center ul {
  display: flex;
  gap: 30px;
}
nav .center ul li a {
  font-weight: 400;
  font-size: var(--p-size);
  line-height: 30px;
  position: relative;
  color: var(--text-color);
  transition: 0.5s;
}
nav .center ul li a::before {
  content: "";
  width: 0;
  height: 2px;
  border-radius: 2px;
  background-color: var(--pink-color);
  position: absolute;
  bottom: -0.25rem;
  left: 50%;
  transition: width 0.4s, left 0.4s;
}
nav .center ul li a:hover {
  color: var(--pink-color);
}
nav .center ul li a:hover::before {
  width: 100%;
  left: 0;
}
nav .right {
  display: flex;
  align-items: center;
  gap: 25px;
}
nav .right select {
  padding: 10px;
  border-radius: 50pc;
  border: 1px solid rgb(203, 203, 203);
  font-size: var(--p-size);
  font-weight: 400;
  line-height: 30px;
}
nav .right .login {
  font-weight: 400;
  font-size: var(--p-size);
  line-height: 30px;
}
nav .right .signup {
  padding: 10px 25px 10px 25px;
  background-color: var(--pink-color);
  border-radius: 10px;
  font-size: var(--p-size);
  line-height: 30px;
  color: var(--white);
}
nav .right .logout {
  font-size: var(--p-size);
  border-radius: 10px;
  padding: 10px 25px 10px 25px;
  background-color: red !important;
  color: var(--white);
  outline: none;
  border: none;
  cursor: pointer;
}
nav .right .bot {
  padding: 10px 25px;
  font-size: var(--p-size);
  border-radius: 10px;
  background-color: rgb(96, 165, 250);
  color: white;
  display: flex;
  gap: 5px;
  align-items: center;
}
nav .right .bot img {
  width: 24px;
}
nav .right .menu {
  display: none;
}

header {
  margin-top: 100px;
  display: flex;
  padding: var(--main-sections-padding);
  display: flex;
  gap: 20px;
}
header .left-head {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 25px;
}
header .left-head h2 {
  font-size: 1.25rem;
  font-weight: 400;
}
header .left-head h1 {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 1.25em;
}
header .left-head a {
  padding: 10px 25px 10px 25px;
  background-color: var(--pink-color);
  border-radius: 10px;
  font-size: var(--p-size);
  line-height: 30px;
  color: var(--white);
}
header .right-head {
  width: 50%;
}

section {
  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
}

.componies {
  width: 100%;
  padding: 50px 0;
}
.componies .top p {
  font-weight: 300;
  font-size: var(--top-p-size);
  line-height: 27.5px;
  text-align: center;
  color: var(--section-top-p);
}
.componies .logos {
  overflow: hidden;
  padding: 40px 0;
  white-space: nowrap;
  position: relative;
  display: flex;
}
.componies .logos::before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--white)) !important;
}
.componies .logos .logos-slide {
  animation: 20s slide infinite linear;
  display: flex;
  align-items: center;
}
.componies .logos .logos-slide img {
  margin: 0 30px;
  max-width: 210px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
.about {
  padding: var(--main-sections-padding);
  display: flex;
  justify-content: space-between;
}
.about .left {
  width: 40%;
  align-content: center;
}
.about .left h1 {
  font-weight: 600;
  font-size: 60px;
  line-height: 60px;
}
.about .left h1 span {
  color: var(--pink-color);
}
.about .left p {
  margin-top: 20px;
  font-weight: 300;
  font-size: var(--p-size);
  line-height: 27.5px;
  color: var(--section-top-p);
}
.about .right {
  width: 60%;
  display: flex;
  justify-content: end;
}
.about .right img {
  width: 100%;
}

.services-main {
  margin-top: 150px;
}
.services-main .top p {
  font-weight: 300;
  font-size: var(--top-p-size);
  line-height: 27.5px;
  text-align: center;
  color: var(--section-top-p);
}
.services-main .bottom {
  padding: var(--main-sections-padding);
  margin-top: 50px;
  display: flex;
  gap: 40px;
  align-items: flex-end;
  height: 550px;
  position: relative;
}
.services-main .bottom video, .services-main .bottom video source {
  position: absolute;
  bottom: -70px;
  left: 0;
  z-index: -10;
}
.services-main .bottom .left,
.services-main .bottom .right {
  width: 50%;
  height: 100%;
}
.services-main .bottom .left {
  border-radius: 20px;
  background-image: url("imgs/voiceclone.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: 0.5s;
}
.services-main .bottom .left:hover {
  transform: scale(1.05);
}
.services-main .bottom .left .text {
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.services-main .bottom .left .text h1 {
  font-size: 2rem;
}
.services-main .bottom .right {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.services-main .bottom .right .service__item,
.services-main .bottom .right .service__items {
  height: 50%;
  width: 100%;
}
.services-main .bottom .right .service__item {
  border-radius: 20px;
  padding: 30px;
  background-image: url(imgs/speechtotext.jpg);
  transition: 0.5s;
}
.services-main .bottom .right .service__item:hover {
  transform: scale(1.05);
}
.services-main .bottom .right .service__item .text {
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.services-main .bottom .right .service__item .text h1 {
  font-size: 2rem;
}
.services-main .bottom .right .service__items {
  display: flex;
  gap: 25px;
}
.services-main .bottom .right .service__items div {
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  transition: 0.5s;
  width: 50%;
}
.services-main .bottom .right .service__items div:hover {
  transform: scale(1.05);
}
.services-main .bottom .right .service__items div:first-child {
  background-color: var(--pink-color);
}
.services-main .bottom .right .service__items div:last-child {
  background-color: #ffcc00;
}

.advantages {
  padding: var(--main-sections-padding);
  margin-top: 100px;
}
.advantages .top p {
  font-weight: 300;
  font-size: var(--top-p-size);
  line-height: 27.5px;
  text-align: center;
  color: var(--section-top-p);
}
.advantages .bottom {
  margin-top: 30px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.advantages .bottom fieldset {
  display: inline-block;
  padding-inline: 50px;
  padding-top: 25px;
  border-radius: 10px;
  padding-bottom: 25px;
  width: 50%;
  border: solid var(--pink-color);
}
.advantages .bottom fieldset legend {
  font-size: 2rem;
  padding: 10px;
}
.advantages .bottom fieldset li {
  list-style: disc;
  font-weight: 300;
  font-size: 25px;
  line-height: 27.5px;
  color: var(--section-top-p);
  margin-bottom: 10px;
}

.guide {
  display: flex;
  justify-content: space-between;
  padding: var(--main-sections-padding);
  margin-top: 150px;
  margin-bottom: 100px;
}
.guide .left {
  width: 37.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.guide .left h1 {
  font-weight: 600;
  font-size: 80px;
  line-height: 80px;
}
.guide .left p {
  font-weight: 400;
  font-size: var(--top-p-size);
  line-height: 27.5px;
  color: var(--section-top-p);
}
.guide .right {
  width: 57.5%;
}
.guide .right iframe {
  border-radius: 30px;
}

footer {
  background-color: var(--text-color);
  padding: var(--main-sections-padding);
  position: relative;
  z-index: 999;
}
footer .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .top .left img {
  max-width: 154px;
}
footer .top .center ul {
  display: flex;
  gap: 20px;
}
footer .top .center ul a {
  color: var(--white);
  font-weight: 400;
  font-size: var(--p-size);
  line-height: 30px;
}
footer .top .right ul {
  display: flex;
  gap: 10px;
}
footer .bottom {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
footer .bottom .left p {
  font-weight: 250;
  font-size: var(--p-size);
  line-height: 30px;
  color: var(--section-top-p);
}
footer .bottom .left p span {
  color: var(--white);
}
footer .bottom .right a {
  font-weight: 500;
  font-size: var(--p-size);
  line-height: 30px;
  color: var(--white);
}

.telegram-notification {
  max-width: 320px;
  border-width: 1px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 1rem;
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 555;
}
.telegram-notification .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}
.telegram-notification .header .close {
  cursor: pointer;
}
.telegram-notification .header .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: rgb(96, 165, 250);
  padding: 0.5rem;
}
.telegram-notification .header .icon svg {
  height: 1rem;
  width: 1rem;
}
.telegram-notification .header .alert {
  font-weight: 600;
  flex: 1;
  color: rgb(107, 114, 128);
}
.telegram-notification .message {
  margin-top: 1rem;
  color: rgb(107, 114, 128);
}
.telegram-notification .actions {
  margin-top: 1.5rem;
}
.telegram-notification .actions a {
  text-decoration: none;
}
.telegram-notification .mark-as-read,
.telegram-notification .read {
  display: inline-block;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.75rem 1.25rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}
.telegram-notification .read {
  background-color: rgb(59, 130, 246);
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}/*# sourceMappingURL=style.css.map */