.text {
  height: 100%;
}
.text .text-bottom {
  height: 100%;
}
.text .text-bottom form {
  height: 100%;
}
.text .text-bottom textarea {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: rgb(224, 224, 224);
  border-radius: 15px;
  padding: 15px;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  resize: none;
}
.text .text-bottom textarea::-webkit-scrollbar-thumb {
  display: none;
}/*# sourceMappingURL=tts.css.map */