@media screen and (max-width: 1300px) {
  .sts {
    height: auto;
  }
  .sts .container {
    gap: 20px;
  }
}
@media screen and (max-width: 1205px) {
  .sts {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 1110px) {
  .sts .container .tools {
    flex-wrap: wrap;
  }
  .sts .container .tools .tool:first-child, .sts .container .tools .tool:nth-child(2) {
    width: 48%;
  }
  .sts .container .tools .tool:last-child {
    width: 100%;
  }
  .stt .container .tools .file, .stt .container .tools .result-text {
    width: 100%;
  }
}
@media screen and (max-width: 774px) {
  .sts .container .tools .tool {
    min-width: 315px;
  }
  .sts .container .tools .tool:first-child .upload-bottom .file-upload-label {
    padding: 30px 30px;
  }
}
@media screen and (max-width: 750px) {
  .sts .container .tool {
    width: 100% !important;
  }
}
@media screen and (max-width: 617px) {
  .sts {
    padding-left: 25px;
    padding-right: 25px;
  }
  .sts .container {
    background-repeat: repeat;
  }
  .sts .container .top h1 {
    font-size: 50px;
    line-height: 50px;
  }
}
@media screen and (max-width: 566px) {
  .sts .container .center {
    flex-wrap: wrap;
    gap: 5px;
  }
}
@media screen and (max-width: 426px) {
  .sts .container .top h1 {
    font-size: 40px;
    line-height: 40px;
  }
}/*# sourceMappingURL=service.css.map */