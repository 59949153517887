:root {
  --top-p-size: 25px;
  --main-sections-padding: 25px 100px;
  --p-size: 20px;
  --text-color: rgba(34, 34, 34, 1);
  --pink-color: rgba(255, 43, 158, 1);
}

.team {
  padding: var(--main-sections-padding);
  margin-bottom: 50px;
}
.team .hidden {
  display: none;
}
.team .loading {
  text-align: center;
  font-size: 24px;
  margin-top: 50px;
}
.team .top h1 {
  font-weight: 600;
  font-size: 60px;
  color: var(--text-color);
}
.team .top p {
  margin-top: 20px;
  font-weight: 300;
  font-size: var(--top-p-size);
}
.team .bottom {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
}
.team .bottom .card {
  width: 240px;
  max-height: 375px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.team .bottom .card .cardTop {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
  height: 250px;
  background-size: cover;
}
.team .bottom .card .cardBottom {
  height: 29%;
  padding: 20px;
}
.team .bottom .card .cardBottom h2 {
  font-size: var(--p-size);
  font-weight: 500;
}
.team .bottom .card .cardBottom p {
  font-weight: 300;
  font-size: 13px;
}

@media screen and (max-width: 1025px) {
  :root {
    --main-sections-padding: 15px 50px;
    --p-size: 1rem;
    --top-p-size: 20px;
  }
  .AboutPage .left h1 {
    font-size: 60px;
    line-height: 60px;
  }
  .AboutPage .right img {
    max-width: 500px;
  }
}
@media screen and (max-width: 832px) {
  .AboutPage {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .team .bottom {
    justify-content: space-between;
  }
  .team .bottom .card {
    width: 230px;
  }
}
@media screen and (max-width: 580px) {
  .team .bottom .card {
    width: 220px;
  }
}
@media screen and (max-width: 560px) {
  .team .bottom .card {
    width: 210px;
  }
}
@media screen and (max-width: 540px) {
  :root {
    --main-sections-padding: 15px 40px;
  }
  .team .bottom .card {
    width: 200px;
  }
}
@media screen and (max-width: 520px) {
  .AboutPage .right {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  :root {
    --main-sections-padding: 15px 30px;
  }
}
@media screen and (max-width: 480px) {
  :root {
    --main-sections-padding: 15px 20px;
  }
}
@media screen and (max-width: 460px) {
  .team .bottom .card {
    max-width: 100%;
    width: 100%;
    max-height: 450px;
  }
  .team .bottom .card .cardTop {
    height: 350px;
  }
}/*# sourceMappingURL=team.css.map */