/* Preloader.css */
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff; /* Background color of the preloader screen */
    z-index: 9999; /* Ensure it appears on top */
  }
  
  .preloader img {
    width: 100px; /* Adjust the size of the preloader gif */
    height: 100px;
  }
  