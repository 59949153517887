.services-page {
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr; // Default holat: Yonma-yon joylashish
  background-image: url(../../../imgs/servicebg.png);
  background-repeat: no-repeat;
  background-size: cover;
  transition: grid-template-columns 0.3s ease;

  .menu{
    display: none;
  }

  .ServicesNav {
    width: 250px;
    height: 100%;
    padding: 20px;
    border-right: 1px solid #e2e2e2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.8);
    transition: width 0.3s ease;
    position: relative;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        max-width: 150px;
        transition: all 0.3s ease;
        cursor: pointer;
      }

      .logosmall{
        display: none;
      }

      button {
        border: none;
        outline-style: none;
        display: flex;
        justify-content: center;
        background-color: transparent;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .center {
      margin-top: 10px;
      flex: 1;
      margin-top: 50px;

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 10px;

        a {
          display: flex;
          align-items: center !important;
          gap: 10px;
          text-decoration: none;
          color: black;
          padding: 5px;
          border-radius: 10px;

          img {
            width: 30px;
          }

          &:hover {
            background-color: #f2f2f2;
          }
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .burger{
        display: none;
      }
      .language{
        select{
          padding: 7px;
          border-radius: 10px;
          width: 100%;
        }
      }
      
      .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100% !important;
        .left {
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
        .right button{
          border: none;
          background-color: transparent;
          outline: none;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .services-page {
    .ServicesNav {
      width: 60px;
      align-items: center;
      padding: 3px;
      position: fixed;
      padding: 15px 3px;
      .top{
        .logosmall{
          display: flex;
          width: 30px;
        }
      }
      .top .logo,
      .center ul li,
      .bottom .cent,
      .bottom .right {
        display: none;
      }
    }

    .right__items {
      .sts {
        padding: 50px 50px 50px 110px;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .services-page {
    display: block !important;
    height: auto; 

    .ServicesNav {
      width: 100%;
      position: relative;
      border-right: none;
      border-bottom: 1px solid #e2e2e2; 
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 5px 20px;
      .center{
        flex: none;
        margin: 0;
        align-items: center;
        justify-content: center;
      }
      .center ul{
        flex-direction: row;
      }
      .bottom{
        flex-direction: row;
        align-items: center;
        .language{
          select{
            width: auto;

          }
        }
      }
    }

    .right__items {
      padding-top: 10px;
      .sts {
        padding: 30px;
      }
    }
  }
}

@media  screen and (max-width:426px) {
  .services-page{
    .menu{
      position: fixed;
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100vh;
      z-index: 99999;
      .container{
        width: 70%;
        height: 100vh;
        position: absolute;
        right: 0;
        background-color: #fff;
        padding: 20px;
        transition: 0.5s;
        animation-name: hamburger;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
  
        @keyframes hamburger {
          from {
            right: -50%;
          }
          to {
            right: 0;
          }
        }
        .top{
          display: flex;
          justify-content: space-between;
          align-items: center;
          img{
            width: 130px;
            &:last-child{
              width: 24px;
            }
          }
        }
        .center {
          margin-top: 10px;
          flex: 1;
          margin-top: 50px;
    
          ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 10px;
    
            a {
              display: flex;
              align-items: center !important;
              gap: 10px;
              text-decoration: none;
              color: black;
              padding: 5px;
              border-radius: 10px;
    
              img {
                width: 30px;
              }
    
              &:hover {
                background-color: #f2f2f2;
              }
            }
          }
        }
        .bottom{
          .cont{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100% !important;
            .left {
              img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
    .ServicesNav{
      .center, .bottom .container{
        display: none;
      }
      .bottom{
        .language select{
          padding: 5px;
        }
        .burger{
          display: block;
          width: 30px;
        }
      }
    }
  }
}