.nav .center ul li a {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
}

.nav .right a:first-child {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 500;
}

.nav .right {
    gap: 10px;
}

.nav .right svg{
    color: #FFFFFF;
    display: none;
}

.nav .right a:nth-child(2) {
    padding: 8px 30px;
    border: 1px solid #FFFFFF;
    font-weight: 500;
    border-radius: 70px;
    font-size: 16px;
    color: #FFFFFF;
    background: linear-gradient(94.59deg, rgba(255, 255, 255, 0.2) 0.56%, rgba(153, 153, 153, 0) 99.44%);
}

@media screen and (max-width:1223px) {
    .nav {
        padding: 25px 50px;
    }
}   

@media screen and (max-width:769px){
    .nav .right svg{
        display: flex;
    }
}