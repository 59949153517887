.HowWork{
    background-color: #101010;
    padding: 25px 100px;
}

.HowWork .top{
    text-align: center;
}

.HowWork .top h1{
    font-size: 39px;
    font-weight: 500;
    background: linear-gradient(90deg, #9F9F9F 0%, #FFFFFF 51.5%, #999999 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
}

.HowWork .bottom{
    border-radius: 30px;
    background: #131313;
    min-height: 554px;
    margin-top: 25px;
}

.HowWork .bottom .wave{
    background-image: url(../../imgs/headerwave.png);
    background-repeat: no-repeat;
    background-position: center ;
    background-size: 100% 500px;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HowWork .bottom .items{
    display: flex;
    padding: 0 75px 75px 75px;
    justify-content: space-between; 
}

.HowWork .bottom .items .item{
    max-width: 334px;
}

.HowWork .bottom .items h2{
    background: linear-gradient(90deg, #9F9F9F 0%, #FFFFFF 51.5%, #999999 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
    font-weight: 500;
    font-size: 25px;
    line-height:27.5px;
}

.HowWork .bottom .items p{
    color: #FFFFFF;
    font-weight: 400;
    font-size: 14px;
    line-height: 18.2px;
    margin-top: 20px;
}

@media screen and (max-width:1223px) {
    .HowWork {
        padding: 25px 50px;
    }

    .HowWork .bottom .items{
        padding: 0 50px 50px 50px;
    }
} 

@media screen and (max-width:1025px) {
    .HowWork .bottom .items{
        padding: 0 40px 40px 40px;
    }

    .HowWork .bottom .items .item{
        max-width: 250px;
    }
}