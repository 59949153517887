.prices {
  margin-top: 100px;
  padding: var(--main-sections-padding);
  margin-bottom: 50px;
}
.prices .top {
  text-align: center;
}
.prices .top h1 {
  font-weight: 600;
  font-size: 60px;
  color: var(--text-color);
}
.prices .top p {
  font-weight: 300;
  font-size: var(--top-p-size);
  margin-top: 10px;
}
.prices .center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
}
.prices .center a {
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 300;
  font-size: var(--p-size);
  color: var(--pink-color);
  border: 1px solid var(--pink-color);
}
.prices .center a.mark {
  color: white;
  background: var(--pink-gradient);
}
.prices .bottom {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 50px;
}
.prices .bottom .card {
  max-width: 350px;
  width: 350px;
  min-width: 300px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 25px;
}
.prices .bottom .card:nth-child(2) {
  border: 2px solid var(--pink-color);
}
.prices .bottom .card div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.prices .bottom .card h2 {
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}
.prices .bottom .card span {
  text-align: center;
  margin-top: 10px;
}
.prices .bottom .card span sub {
  text-align: center;
  display: inline-block;
  font-size: var(--top-p-size);
}
.prices .bottom .card span h1 {
  display: inline-block;
  font-weight: 400;
  font-size: 60px;
  color: var(--pink-color);
  text-align: center;
}
.prices .bottom .card hr {
  margin-top: 20px;
}
.prices .bottom .card ul {
  margin-top: 20px;
}
.prices .bottom .card ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}
.prices .bottom .card a {
  padding: 5px 30px;
  border-radius: 30px;
  font-weight: 300;
  font-size: var(--p-size);
  border: 1px solid var(--pink-color);
  margin-top: 25px;
  transition: 0.3s;
  background-color: var(--pink-color);
  color: white;
}
.prices .bottom .card a:hover {
  background-color: white;
  color: var(--pink-color);
}
@media screen and (max-width: 1025px) {
  .prices {
    padding: 15px 50px;
  }
  .prices :root {
    --main-sections-padding: 15px 50px;
  }
}/*# sourceMappingURL=prices.css.map */