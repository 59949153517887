.tools .file,
.tools .result-text {
  height: 350px;
  background-color: var(--white);
  border-radius: 20px;
  padding: 25px;
}
.tools .file {
  width: 33%;
}
.tools .file form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}
.tools .file-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tools .file-top p {
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
}
.tools .file-top button {
  padding: 10px 25px;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  background: var(--pink-gradient);
  font-size: 20px;
  line-height: 20px;
  color: var(--white);
}
.tools .file-bottom {
  height: 100%;
}
.tools .file-bottom .file-upload-form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tools .file-bottom .file-upload-label {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgb(224, 224, 224);
  padding: 30px 70px;
  border-radius: 15px;
  border: 2px dashed rgba(0, 0, 0, 0.5);
}
.tools .file-bottom .file-upload-label input {
  display: none;
}
.tools .file-bottom .file-upload-label svg {
  height: 50px;
  fill: var(--pink-color);
  margin-bottom: 20px;
}
.tools .file-bottom .file-upload-design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.tools .file-bottom .browse-button {
  background-color: var(--pink-color);
  padding: 5px 15px;
  border-radius: 10px;
  color: var(--white);
  transition: all 0.3s;
}
.tools .result-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 66%;
}
.tools .result-text .result-text-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tools .result-text .result-text-top p {
  font-size: 25px;
  line-height: 25px;
  font-weight: 500;
}
.tools .result-text .result-text-top .copy {
  --button-bg: #353434;
  --button-hover-bg: #464646;
  --button-text-color: #cccccc;
  --button-hover-text-color: #8bb9fe;
  --button-border-radius: 10px;
  --button-diameter: 36px;
  --button-outline-width: 1px;
  --button-outline-color: rgb(141, 141, 141);
  --tooltip-bg: #f4f3f3;
  --toolptip-border-radius: 4px;
  --tooltip-font-family: Menlo, Roboto Mono, monospace;
  --tooltip-font-size: 12px;
  --tootip-text-color: rgb(50, 50, 50);
  --tooltip-padding-x: 7px;
  --tooltip-padding-y: 7px;
  --tooltip-offset: 8px;
}
.tools .result-text .result-text-top .copy {
  box-sizing: border-box;
  width: var(--button-diameter);
  height: var(--button-diameter);
  border-radius: var(--button-border-radius);
  background: var(--pink-gradient);
  color: var(--white);
  border: none;
  cursor: pointer;
  position: relative;
  outline: none;
}
.tools .result-text .result-text-top .tooltip {
  position: absolute;
  opacity: 0;
  visibility: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font: var(--tooltip-font-size) var(--tooltip-font-family);
  color: var(--tootip-text-color);
  background: var(--tooltip-bg);
  padding: var(--tooltip-padding-y) var(--tooltip-padding-x);
  border-radius: var(--toolptip-border-radius);
  pointer-events: none;
  transition: all var(--tooltip-transition-duration) cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.tools .result-text .result-text-top .tooltip::before {
  content: attr(data-text-initial);
}
.tools .result-text .result-text-top .tooltip::after {
  content: "";
  position: absolute;
  bottom: calc(var(--tooltip-padding-y) / 2 * -1);
  width: var(--tooltip-padding-y);
  height: var(--tooltip-padding-y);
  background: inherit;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  z-index: -999;
  pointer-events: none;
}
.tools .result-text .result-text-top .copy svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.tools .result-text .result-text-top .checkmark {
  display: none;
}
.tools .result-text .result-text-top .copy:hover .tooltip,
.tools .result-text .result-text-top .copy:focus:not(:focus-visible) .tooltip {
  opacity: 1;
  visibility: visible;
  top: calc((100% + var(--tooltip-offset)) * -1);
}
.tools .result-text .result-text-top .copy:focus:not(:focus-visible) .tooltip::before {
  content: attr(data-text-end);
}
.tools .result-text .result-text-top .copy:focus:not(:focus-visible) .clipboard {
  display: none;
}
.tools .result-text .result-text-top .copy:focus:not(:focus-visible) .checkmark {
  display: block;
}
.tools .result-text .result-text-top .copy:hover,
.tools .result-text .result-text-top .copy:focus {
  background-color: var(--button-hover-bg);
}
.tools .result-text .result-text-top .copy:active {
  outline: var(--button-outline-width) solid var(--button-outline-color);
}
.tools .result-text .result-text-bottom {
  height: 100%;
}
.tools .result-text .result-text-bottom .container {
  width: 100%;
  max-height: 80%;
  background-color: rgb(224, 224, 224);
  border-radius: 20px;
  padding: 25px;
  overflow: scroll;
}
.tools .result-text .result-text-bottom .container::-webkit-scrollbar {
  display: none;
}/*# sourceMappingURL=stt.css.map */