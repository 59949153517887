*{
    font-family: "Plus Jakarta Sans", sans-serif;
}

.header{
    background-image: url(../../imgs/headerbg.png);
    background-color: black;
    padding: 100px 0;
}

.header .pad{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding-inline: 120px;
}

.header span{
    color: white;
    border: 0.5px solid #E4E4E4;
    padding: 5px 15px;
    border-radius: 160px;
    font-size: 14px;
    font-weight: 500;
}

.header h1{
    font-weight: 600;
    font-size: 80px;
    line-height: 88px;
    color: #E4E4E4;
}

.header .btns{
    display: flex;
    gap: 10px;
    align-items: center;
}

.header .btns a:first-child{
    color: #FFFFFF;
    padding:8px 30px 8px 30px;
    border-radius: 70px;
    border: 0.5px solid #FFFFFF;
    background: linear-gradient(94.59deg, rgba(255, 255, 255, 0.2) 0.56%, rgba(153, 153, 153, 0) 99.44%);
    font-weight: 500;
    transition: 0.5s;
}

.header .btns a:first-child:hover{
    background: linear-gradient(-94.59deg, rgba(255, 255, 255, 0.2) 0.56%, rgba(153, 153, 153, 0) 99.44%);

}

.header .btns a:last-child{
    color: white;
    display: flex;
    align-items: center;
    font-weight: 500;
    gap: 5px;
}

.header .wave{
    width: 100%;
    background-image: url(../../imgs/headerwave.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    display: flex;
    min-height: 450px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

@media screen and (max-width:1025px) {
    .header .pad{
        padding-inline: 50px;
    }
}